<template>
  <div class="page-roles">
    <h2 class="p-title">职责列表</h2>
    <yos-tab-nav :list="tabNav" />
    <div class="p-card fix">
      <yos-button class="yos-button-primary" to="/setting/roles/new">创建职责权限</yos-button>
      <form class="p-search-bar" @submit.prevent="onSubmit">
        <span>职责：</span>
        <yos-input
          v-model.trim="keyword"
          placeholder="搜索职责名称"
        />
        <yos-button type="submit" class="yos-button-primary">搜索</yos-button>
        <yos-button type="reset" @click="onReset">重置</yos-button>
      </form>
      <table cellspacing="0" class="m-table">
        <thead>
          <tr>
            <th>职责名称</th>
            <th>已授权人员</th>
            <th>状态</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataList" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.accountTotal }}个</td>
            <td :class="addClass([item.status !== 1, 'red'])">
              {{item.status === 1 ? '正常' : '禁用'}}
            </td>
            <td>
              <router-link class="option" :to="`/setting/roles/${item.id}`">{{item.name !== '超级管理员' ? '编辑' : '查看'}}</router-link>
              <span
                class="option"
                v-if="item.name !== '超级管理员'"
                @click="onUpdateRole(item.id, item.status === 0 ? 1 : 0)"
              >
                {{item.status === 1 ? '禁用' : '启用'}}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <yos-pager
        :baseUrl="baseUrl"
        :totalPage="totalPage"
        @change="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import YosButton from '../../components/form/yos-button'
import YosInput from '../../components/form/yos-input'
import YosPager from '../../components/other/yos-pager'
import YosTabNav from '../../components/other/tab-nav'
import { reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { usePage, useServer, useToast, useTabNav, useDialog } from '../../hook'
import { addClass, serialize } from '../../util'
import {
  getRoles,
  updateRoleByStatus
} from '../../data'

export default {
  name: 'Roles',
  components: {
    YosButton,
    YosInput,
    YosPager,
    YosTabNav
  },
  setup() {
    const toast = useToast()
    const dialog = useDialog()
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      keyword: route.query.keyword || '',
      totalPage: -1,
      dataList: []
    })
    const url = '/setting/roles'
    const nav = [{
      name: '账号列表',
      to: '/setting/accounts'
    }, {
      name: '职责列表',
      to: '/setting/roles'
    }]

    const { page, baseUrl } = usePage(url, { keyword: state.keyword })

    const setRolesParams = useServer(getRoles, ({ roles, totalPage }) => {
      state.dataList = roles
      state.totalPage = totalPage
    })


    const setUpdateRoleByStatusParams = useServer(updateRoleByStatus, ({ msg }, { id, status }) => {
      toast(msg)

      state.dataList = state.dataList.map(item => {
        if (item.id === id) {
          item.status = status
        }
        return item
      })
    })

    const onUpdateRole = (id, status) => {
      const title = status !== 1 ? '禁用职责' : '启用职责'
      const content = status !== 1 ? '确认禁用该职责？/n禁用后，如账号下所有职责被禁用，视为没有权限用户，登陆后没有菜单可操作。' : '确认启用该职责？/n启用后，该职责状态恢复正常状态。'
      dialog({
        title,
        content,
        onOk: () => {
          setUpdateRoleByStatusParams({id, status})
        }
      })
    }

    const onSubmit = () => {
      const params = { keyword: state.keyword, page: 1 }
      router.replace(serialize(url, params))
      setRolesParams(params)
    }

    const onReset = () => {
      state.keyword = ''
      onSubmit()
    }

    const onPageChange = (page) => {
      setRolesParams({ keyword: state.keyword, page })
    }

    setRolesParams({ keyword: state.keyword, page })

    return {
      tabNav: useTabNav(nav),
      ...toRefs(state),
      page,
      baseUrl,
      addClass,
      onUpdateRole,
      onPageChange,
      onSubmit,
      onReset
    }
  }
}
</script>

<style>

</style>
