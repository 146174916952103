<template>
  <div class="yos-tab-nav fix">
    <router-link
      v-for="item in list"
      :class="`item ${item.active ? 'router-link-active' : ''}`"
      :to="item.to"
      :key="item.name"
    >
      {{item.name}}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'TabNav',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="postcss">
.yos-tab-nav {
  line-height: 40px;
  border-bottom: 2px solid #e0e1e2;
  font-size: 16px;
  margin-top: 18px;


  .item {
    color: #353535;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    padding: 0 10px;
    display: inline-block;
    float: left;
    margin-bottom: -2px;

    &.router-link-active {
      border-bottom: 3px solid #1aad19;
      color: #1aad19;
    }
  }
}
</style>
