<template>
  <div class="ui-nothing">
    <img class="pic" src="@/assets/images/null.png">
    <div class="content">
      <h4 class="title">暂无结果</h4>
      <p>请重置筛选条件，并重新搜索。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nothing'
}
</script>

<style lang="postcss">
.ui-nothing {
  padding: 100px 0;
  text-align: center;

  .pic {
    width: 190px;
  }

  .content {
    display: inline-block;
    text-align: left;
    color: #999;
    padding: 50px 0;

    .title {
      font-weight: normal;
      font-size: 18px;
      color: #333;
    }
  }
}
</style>
