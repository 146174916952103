<template>
  <nothing v-if="totalPage === 0" />
  <form v-else-if="totalPage > 0" class="yos-pager" @submit.prevent="onSubmit">
    <button
      class="icon-perv"
      :disabled="page <= 1"
      @click="onGoTo(page - 1)"
      type="button"
    ></button>
    <span class="page">{{page}}/{{totalPage}}</span>
    <button
      class="icon-next"
      :disabled="page >= totalPage"
      @click="onGoTo(page + 1)"
      type="button"
    ></button>
    <input type="number" class="num" v-model.trim.number="num" />
    <button
      type="submit"
      class="go-to"
      :disabled="num < 1 || num > totalPage"
    >
      跳转
    </button>
  </form>
</template>

<script>
import Nothing from '../../components/common/nothing'
import { inject, computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { serialize } from '../../util'
export default {
  components: {
    Nothing
  },
  props: {
    baseUrl: {
      type: String,
      default: '/'
    },
    totalPage: {
      type: Number,
      default: 0
    }
  },
  setup(props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const page = computed(() => parseInt(route.query.page || 1))
    const num = ref(route.query.page || '1')

    const onGoTo = (page) => {
      let nextPage = page || route.query.page || 1

      if (nextPage < 1 || nextPage > props.totalPage) {
        return
      }

      num.value = page
      router.replace(serialize(props.baseUrl, {page: nextPage}))
      emit('change', nextPage)
    }

    const onSubmit = () => {
      onGoTo(parseInt(num.value))
    }

    return {
      num,
      page,
      onGoTo,
      onSubmit
    }
  }
}
</script>

<style lang="postcss">
  .yos-pager {
    text-align: right;
    padding: 8px 0;
    margin-top: 10px;

    .icon-perv, .icon-next {
      border: 1px solid #e5e5e5;
      background-color: #fff;
      width: 25px;
      height: 25px;
      color: #999;
      padding: 0;
      cursor: pointer;

      &:disabled {
        color: #ccc;
        cursor: not-allowed;
      }
    }

    .page {
      margin: 0 10px;
    }

    .num {
      border: 1px solid #e5e5e5;
      width: 70px;
      height: 25px;
      margin: 0 10px;
      text-align: center;
    }

    .go-to {
      border: 1px solid #e5e5e5;
      height: 25px;
      background-color: #fff;
      padding: 0 10px;
      cursor: pointer;

      &:disabled {
        color: #ccc;
        cursor: not-allowed;
      }
    }
  }
</style>
